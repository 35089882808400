<template>
    <div>
        <el-page-header @back="goBack" content="赛事编辑">
        </el-page-header>
    </div>
</template>

<script>
    export default {
        name: "eventEdit",
        methods:{
            goBack(){
                this.$router.push('/eventList')
            }
        }
    }
</script>

<style scoped>

</style>